// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: 'AIzaSyAR-T5l4PlvgSldUPMZmwHNMz2TlykfvsI',
    authDomain: 'air-rive-landing.firebaseapp.com',
    projectId: 'air-rive-landing',
    storageBucket: 'air-rive-landing.appspot.com',
    messagingSenderId: '1005153310478',
    appId: '1:1005153310478:web:73a81ed587b38488b8a95f',
    measurementId: 'G-GDTDJ81TRJ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)

export const functions = getFunctions(app)
