import { Outlet } from 'react-router-dom'
import Alert from './components/Alert.tsx'
import { useEffect, useState } from 'react'
import { db } from './utils/firebase.ts'
import { collection, getDocs, where, query } from 'firebase/firestore'

function App() {
    const [alert, setAlert] = useState({
        type: '',
        msg: '',
        url: '',
        location: '',
    })

    const fetchAlerts = async () => {
        const alertRef = collection(db, 'alerts')
        const getActive = query(alertRef, where('active', '==', true))
        const getAlerts = await getDocs(getActive)
        return getAlerts.forEach((alert) => {
            console.log(alert.data())
            setAlert({
                type: alert.data().type,
                url: alert.data().url,
                msg: alert.data().msg,
                location: alert.data().campus,
            })
        })
    }

    useEffect(() => {
        fetchAlerts()
    }, [])

    return (
        <div className="m-5 flex flex-col gap-5 font-body text-lg">
            <nav className="flex flex-col items-center justify-between md:flex-row">
                <a
                    className="flex w-fit flex-row items-center justify-center gap-3 rounded-2xl px-3 py-2 font-heading text-4xl font-extrabold text-neon-blue outline-2 outline-neon-blue hover:outline"
                    href={'/'}
                >
                    <img
                        className="h-12 w-12"
                        src="https://firebasestorage.googleapis.com/v0/b/air-rive-landing.appspot.com/o/corporate%2FLayer%201.svg?alt=media&token=d0e58ef1-4f7d-4f9b-a475-27c197158e3e"
                        alt="Bird carrying a bag, on a blue background."
                    />
                    <h1>AIR-RIVE</h1>
                </a>
                <div className="flex flex-row gap-3">
                    <a
                        className="rounded-xl bg-neon-blue px-3 py-2 text-white outline outline-2 outline-neon-blue transition duration-300 hover:bg-white hover:text-neon-blue"
                        href="https://try.air-rive.com"
                    >
                        Customer
                    </a>
                    <a
                        href="https://partners.air-rive.com"
                        className="rounded-xl bg-neon-blue px-3 py-2 text-white outline outline-2 outline-neon-blue transition duration-300 hover:bg-white hover:text-neon-blue"
                    >
                        Merchant
                    </a>
                </div>
            </nav>
            <Alert
                type={alert.type}
                msg={alert.msg}
                url={alert.url}
                location={alert.location}
            />
            <section className="min-h-screen">
                <Outlet />
            </section>
            <footer className="rounded-2xl bg-neon-blue p-5">
                <div className="flex flex-col items-center justify-between gap-8 md:flex-row">
                    <div className="order-last w-fit md:order-first">
                        <div className="flex flex-row items-center justify-center gap-3 font-heading text-4xl font-bold text-white md:justify-normal">
                            <img
                                className="h-12 w-12"
                                src="https://firebasestorage.googleapis.com/v0/b/air-rive-landing.appspot.com/o/corporate%2Fwhite.svg?alt=media&token=a66954a9-65c9-4a91-9ba8-d3dd49e2aa0d"
                                alt="Bird carrying a bag, on a white background."
                            />
                            <h1>AIR-RIVE</h1>
                        </div>
                        <h2 className="mt-2 font-heading text-2xl font-bold text-white">
                            Join us in the sky.
                        </h2>
                        <div className="my-3 flex flex-row justify-center gap-5 font-heading text-2xl font-bold md:justify-normal">
                            <a className="rounded-xl bg-white px-2 py-1 text-neon-blue outline outline-2 outline-offset-0 outline-white transition duration-300 hover:bg-neon-blue hover:text-white">
                                <i className="fa-brands fa-x-twitter"></i>
                            </a>
                            <a className="rounded-xl bg-white px-2 py-1 text-neon-blue outline outline-2 outline-offset-0 outline-white transition duration-300 hover:bg-neon-blue hover:text-white">
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a className="rounded-xl bg-white px-2 py-1 text-neon-blue outline outline-2 outline-offset-0 outline-white transition duration-300 hover:bg-neon-blue hover:text-white">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a className="rounded-xl bg-white px-2 py-1 text-neon-blue outline outline-2 outline-offset-0 outline-white transition duration-300 hover:bg-neon-blue hover:text-white">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between gap-8 text-white md:w-fit md:justify-normal">
                        <div className="flex flex-col">
                            <h3 className="font-heading text-2xl font-bold text-white">
                                Company
                            </h3>
                            <a
                                href="/corp"
                                className="underline-offset-2 hover:underline"
                            >
                                About
                            </a>
                            {/*}
                            <a
                                href="/corp/careers"
                                className="underline-offset-2 hover:underline"
                            >
                                Careers
                            </a>
                            */}
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="font-heading text-2xl font-bold text-white">
                                Get in Touch
                            </h3>
                            <a href='tel:5202001670'>+1 520-200-1670</a>
                            <p>1718 E Speedway Blvd # 4326<br/>
                                Tucson, AZ 85719<br/>
                                United States</p>
                        </div>
                    </div>
                </div>
                <hr className="my-3 h-1 w-full self-center justify-self-center rounded-3xl bg-white" />
                <div className="flex flex-col justify-between md:flex-row">
                    <div className="flex flex-row flex-wrap justify-center gap-2 text-white md:gap-3">
                        <a
                            href="/legal/tos"
                            className="underline-offset-2 hover:underline"
                        >
                            Terms of service
                        </a>
                        <a
                            href="/legal/privacy"
                            className="underline-offset-2 hover:underline"
                        >
                            Privacy policy
                        </a>
                        <a
                            href="/unsubscribe"
                            className="underline-offset-2 hover:underline"
                        >
                            Unsubscribe
                        </a>
                    </div>
                    <p className="text-center text-white">
                        &copy; 2024 AIR-RIVE. All rights reserved.
                        AIR-RIVE&trade;<span>&#8480;</span>
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default App
