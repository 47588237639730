export function Privacy() {
    return (
        <section className="flex flex-col gap-10 scroll-smooth">
            <div className="flex h-80 flex-col items-center justify-center gap-3 rounded-xl bg-neon-blue p-5 text-white">
                <h1 className="text-center font-heading text-3xl font-bold">
                    Privacy Policy
                </h1>
                <p>Last updated December 4th, 2023</p>
            </div>
            <div className="flex flex-col gap-5">
                <p>
                    This privacy notice for AIR-RIVE Corporate. (doing business
                    as AIR-RIVE) ("we", "us", or "our), describes how and why we
                    might collect, store, use, and/or share ("process") your
                    information when you use our ("Services"), such as when you:
                </p>
                <ul className="mx-10 list-disc">
                    <li>
                        Visit our website at <a>air-rive.com</a>, or any website
                        of ours that links to this privacy notice.
                    </li>
                    <li>
                        Download and use our mobile application (AIR-RIVE), or
                        any other application of ours that links to this privacy
                        notice.
                    </li>
                    <li>
                        Engage with us in other related ways, including any
                        sales, marketing, or events.
                    </li>
                </ul>
                <p>
                    <span className="font-bold">Questions or concerns?</span>{' '}
                    Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services. If
                    you still have any questions or concerns, please contact us
                    at{' '}
                    <a
                        href="mailto:hello@air-rive.com"
                        className="text-neon-blue hover:underline"
                    >
                        hello@air-rive.com
                    </a>
                    .
                </p>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <h2 className="font-heading text-2xl font-bold">
                    Summary of Key Points
                </h2>
                <strong>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our{' '}
                    <a href="#toc" className="text-neon-blue hover:underline">
                        table of contents
                    </a>{' '}
                    below to find the section you are looking for.
                </strong>
                <p>
                    <span className="font-bold">
                        What personal information do we process?
                    </span>{' '}
                    When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with us and the Services, the choices you make, and the
                    products and features you use. Learn more about{' '}
                    <a
                        href="#collected"
                        className="text-neon-blue hover:underline"
                    >
                        personal information you disclose to us
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        Do we process any sensitive personal information?
                    </span>{' '}
                    We may process sensitive personal information when necessary
                    with your consent or as otherwise permitted by applicable
                    law. Learn more about{' '}
                    <a
                        href="#collected"
                        className="text-neon-blue hover:underline"
                    >
                        sensitive information we process
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        Do we receive any information from third parties?
                    </span>{' '}
                    We may receive information from public databases, marketing
                    partners, social media platforms, and other outside sources.
                    Learn more about{' '}
                    <a
                        href="#thirdparty"
                        className="text-neon-blue hover:underline"
                    >
                        information collected from other sources
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        How do we process your information?
                    </span>{' '}
                    We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with the law. We may
                    also process your information for other purposes with your
                    consent. We process your information only when we have a
                    valid legal reason to do so. Learn more about{' '}
                    <a
                        href="#process"
                        className="text-neon-blue hover:underline"
                    >
                        how we process your information
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        In what situations and with what parties do we share
                        information?
                    </span>{' '}
                    We may share information in specific situations and with
                    specific third parties. Learn more about{' '}
                    <a href="#share" className="text-neon-blue hover:underline">
                        when and with whom we share your personal information
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        How do we keep your information safe?
                    </span>{' '}
                    We have organizational and technical processes and
                    procedures in place to protect your personal information.
                    However, no electronic transmission over the internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not
                    be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Learn more about{' '}
                    <a
                        href="#secure"
                        className="text-neon-blue hover:underline"
                    >
                        how we keep your information safe
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">What are your rights?</span>{' '}
                    Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights
                    regarding your personal information. Learn more about{' '}
                    <a
                        href="#rights"
                        className="text-neon-blue hover:underline"
                    >
                        your privacy rights
                    </a>
                    .
                </p>
                <p>
                    <span className="font-bold">
                        How do you exercise your rights?
                    </span>{' '}
                    The easiest way to exercise your rights is by submitting a
                    data subject access request, or by contacting us. We will
                    consider and act upon any request in accordance with
                    applicable data protection laws.
                </p>
                <strong>
                    Want to learn more about what we do with any information we
                    collect? Review the privacy notice in full.
                </strong>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <h2 className="font-heading text-3xl font-bold">
                    Table of Contents
                </h2>
                <ol id="toc" className="mx-10 list-decimal">
                    <li>
                        <a
                            href="#collected"
                            className="text-neon-blue hover:underline"
                        >
                            What information do we collect?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#process"
                            className="text-neon-blue hover:underline"
                        >
                            How do we process your information?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#share"
                            className="text-neon-blue hover:underline"
                        >
                            When and with whom do we share your personal
                            information?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#thirdparty"
                            className="text-neon-blue hover:underline"
                        >
                            What is our stance on third-party websites?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#cookies"
                            className="text-neon-blue hover:underline"
                        >
                            Do we use cookies and other tracking technologies?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#social"
                            className="text-neon-blue hover:underline"
                        >
                            How do we handle your social logins?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#retention"
                            className="text-neon-blue hover:underline"
                        >
                            How long do we keep your information?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#secure"
                            className="text-neon-blue hover:underline"
                        >
                            How do we keep your information safe?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#minors"
                            className="text-neon-blue hover:underline"
                        >
                            Do we collect information from minors?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#rights"
                            className="text-neon-blue hover:underline"
                        >
                            What are your privacy rights?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#dnt"
                            className="text-neon-blue hover:underline"
                        >
                            Controls for DO-NOT-TRACK features
                        </a>
                    </li>
                    <li>
                        <a
                            href="#usrights"
                            className="text-neon-blue hover:underline"
                        >
                            Do United States residents have specific privacy
                            rights?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#updates"
                            className="text-neon-blue hover:underline"
                        >
                            Do we make updates to this notice?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#contact"
                            className="text-neon-blue hover:underline"
                        >
                            How can you contact us about this notice?
                        </a>
                    </li>
                    <li>
                        <a
                            href="#review"
                            className="text-neon-blue hover:underline"
                        >
                            How can you review, update, or delete the data we
                            collect?
                        </a>
                    </li>
                </ol>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="collected" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        1. What information do we collect?
                    </h2>
                    <p>In short: Personal information you disclose to us.</p>
                    <p>
                        We collect personal information that you voluntarily
                        provide to us when you register on the Services, express
                        an interest in obtaining information about us or our
                        products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                    </p>
                    <p>
                        <span className="font-bold">
                            Personal Information Provided by You.
                        </span>{' '}
                        The personal information that we collect depends on the
                        context of your interactions with us and the Services,
                        the choices you make, and the products and features you
                        use. The personal information we collect may include the
                        following:
                        <ul className="mx-10 list-disc">
                            <li>names</li>
                            <li>phone numbers</li>
                            <li>email addresses</li>
                            <li>usernames</li>
                            <li>passwords</li>
                            <li>contact or authentication data</li>
                            <li>debit/credit card numbers</li>
                        </ul>
                    </p>
                    <p>
                        <span className="font-bold">
                            Sensitive Information.
                        </span>{' '}
                        When necessary, with your consent or as otherwise
                        permitted by applicable law, we process the following
                        categories of sensitive information:
                        <ol className="mx-10 list-disc">
                            <li>financial data</li>
                            <li>student data</li>
                        </ol>
                    </p>
                    <p>
                        <span className="font-bold">Payment Data.</span> We may
                        collect data necessary to process your payment if you
                        make purchases, such as your payment instrument number,
                        and the security code associated with your payment
                        instrument. All payment data is stored by Stripe You may
                        find their privacy notice link here:
                        <a
                            className="text-neon-blue hover:underline"
                            href="https://stripe.com/legal/consumer"
                            target="_blank"
                        >
                            {' '}
                            stripe.com/legal/consumer
                        </a>
                    </p>
                    <p>
                        <span className="font-bold">
                            Social Media Login Data.
                        </span>{' '}
                        We may provide you with the option to register with us
                        using your existing social media account details, like
                        your Facebook, Twitter, or other social media account.
                        If you choose to register in this way, we will collect
                        the information described in the section called{' '}
                        <a className="text-neon-blue hover:underline">
                            "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
                        </a>{' '}
                        below.
                    </p>
                    <p>
                        <span className="font-bold">Application Data.</span> If
                        you use our application(s), we also may collect the
                        following information if you choose to provide us with
                        access or permission:
                        <ul className="mx-10 list-disc">
                            <li>
                                <span className="font-bold">
                                    Geological Information.
                                </span>{' '}
                                We may request access or permission to track
                                location-based information from your mobile
                                device, either continuously or while you are
                                using our mobile application(s), to provide
                                certain location-based services. If you wish to
                                change our access or permissions, you may do so
                                in your device's settings.
                            </li>
                            <li>
                                <span className="font-bold">
                                    Mobile Device Access.
                                </span>{' '}
                                We may request access or permission to certain
                                features from your mobile device, including your
                                mobile device's camera, reminders, and other
                                features. If you wish to change our access or
                                permissions, you may do so in your device's
                                settings.
                            </li>
                            <li>
                                <span className="font-bold">
                                    Mobile Device Data.
                                </span>{' '}
                                We automatically collect device information
                                (such as your mobile device ID, model, and
                                manufacturer), operating system, version
                                information and system configuration
                                information, device and application
                                identification numbers, browser type and
                                version, hardware model Internet service
                                provider and/or mobile carrier, and Internet
                                Protocol (IP) address (or proxy server). If you
                                are using our application(s), we may also
                                collect information about the phone network
                                associated with your mobile device, your mobile
                                device's operating system or platform, the type
                                of mobile device you use, your mobile device's
                                unique device ID, and information about the
                                features of our application(s) you accessed.
                            </li>
                        </ul>
                    </p>
                    <p>
                        <span className="font-bold">Push Notifications.</span>{' '}
                        We may request to send you push notifications regarding
                        your account or certain features of the application(s).
                        If you wish to opt out from receiving these types of
                        communications, you may turn them off in your device's
                        settings.
                    </p>
                    <strong>
                        This information is primarily needed to maintain the
                        security and operation of our application(s), for
                        troubleshooting, and for our internal analytics and
                        reporting purposes.
                    </strong>
                    <p>
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Information automatically collected
                    </h3>
                    <p>
                        In Short: Some information such as your internet
                        Protocol (IP) address and/or browser and device
                        characteristics - is collected automatically when you
                        visit our Services
                    </p>
                    <p>
                        We automatically collect certain information when you
                        visit, use, or navigate the Services. This information
                        does not reveal your specific identity (like your name
                        or contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Services, and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and
                        reporting purposes.
                    </p>
                    <p>
                        Like many businesses, we also collect information
                        through cookies and similar technologies.
                    </p>
                    <p>
                        The information we collect includes:
                        <ul className="mx-10 list-disc">
                            <li>
                                <span className="font-bold">
                                    {' '}
                                    Log and Usage data.
                                </span>{' '}
                                Log and usage data is service-related,
                                diagnostic, usage, and performance information
                                our servers automatically collect when you
                                access or use our Services and which we record
                                in log files. Depending on how you interact with
                                us, this log data may include your IP address,
                                device information, browser type, and settings
                                and information about your activity in the
                                Services (such as the date/time stamps
                                associated with your usage, pages and files
                                viewed, searches, and other actions you take
                                such as which features you use), device event
                                information (such as system activity, error
                                reports (sometimes called "crash dumps"). and
                                hardware settings).
                            </li>
                            <li>
                                <span className="font-bold"> Device Data.</span>{' '}
                                We collect device data such as information about
                                your computer, phone, tablet, or other device
                                you use to access the Services. Depending on the
                                device used, this device data may include
                                information such as your IP address (or proxy
                                server), device and application identification
                                numbers, location, browser type, hardware model,
                                Internet service provider and/or mobile carrier,
                                operating system, and system configuration
                                information.
                            </li>
                            <li>
                                <span className="font-bold">
                                    {' '}
                                    Location Data.
                                </span>{' '}
                                We collect location data such as information
                                about your device's location, which can be
                                either precise or imprecise. How much
                                information we collect depends on the type and
                                settings of the device you use to access the
                                Services. For example, we may use GPS and other
                                technologies to collect geolocation data that
                                tells us your current location (based on your IP
                                address). You can opt out of allowing us to
                                collect this information either by refusing
                                access to the information or by disabling your
                                Location setting on your device. However, if you
                                choose to opt out, you may not be able to use
                                certain aspects of the Services.
                            </li>
                        </ul>
                    </p>
                    <strong>Information we collected from other sources</strong>
                    <p>
                        In Short: We may collect limited data from public
                        databases, marketing partners, social media platforms,
                        and other outside sources.
                    </p>
                    <p>
                        In order to enhance our ability to provide relevant
                        marketing, offers, and services to you and update our
                        records, we may obtain information about you from other
                        sources, such as public databases, joint marketing
                        partners, affiliate programs, data providers, social
                        media platforms, and from other third parties. This
                        information includes mailing addresses, job titles,
                        email addresses, phone numbers, intent data (or user
                        behavior data), Internet Protocol (IP) addresses, social
                        media profiles, social media URLs, and custom profiles,
                        for purposes of targeted advertising and event
                        promotion. If you interact with us on a social media
                        platform using your social media account (e.g., Facebook
                        or Twitter), we receive personal information about you
                        such as your name, email address, and gender. Any
                        personal information that we collect from your social
                        media account depends on your social media account's
                        privacy settings.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="process" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        2. How do we process your information?
                    </h2>
                    <p>
                        In Short: We process your information to provide,
                        improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply
                        with law. We may also process your information for other
                        purposes with your consent.
                    </p>
                    <p>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                        <ul className="mx-10 list-disc">
                            <li>
                                To facilitate account creation and
                                authentication and otherwise manage user
                                accounts. We may process your information so you
                                can create and log in to your account, as well
                                as keep your account in working order.
                            </li>
                            <li>
                                To deliver and facilitate delivery of services
                                to the user. We may process your information to
                                provide you with the requested service.
                            </li>
                            <li>
                                To respond to user inquiries/offer support to
                                users. We may process your information to
                                respond to your inquiries and solve any
                                potential issues you might have with the
                                requested service.
                            </li>
                            <li>
                                To send administrative information to you. We
                                may process your information to send you details
                                about our products and services changes to our
                                terms and policies, and other similar
                                information.
                            </li>
                            <li>
                                To fulfill and manage your orders. We may
                                process your information to fulfill and manage
                                your orders, payments, returns, and exchanges
                                made through the Services.
                            </li>
                            <li>
                                To request feedback. We may process your
                                information when necessary to request feedback
                                and to contact you about your use of our
                                Services.
                            </li>
                            <li>
                                To send you marketing and promotional
                                communications. We may process the personal
                                information you send to us for our marketing
                                purposes, if this is in accordance with your
                                marketing preferences. You can opt out of our
                                marketing emails at any time. For more
                                information, see{' '}
                                <a className="text-neon-blue hover:underline">
                                    "WHAT ARE YOUR PRIVACY RIGHTS?"
                                </a>{' '}
                                below.
                            </li>
                            <li>
                                To deliver targeted advertising to you. We may
                                process your information to develop and display
                                personalized content and advertising tailored to
                                your interests, location, and more.
                            </li>
                            <li>
                                To protect our Services. We may process your
                                information as part of our efforts to keep our
                                Services safe and secure, including fraud
                                monitoring and prevention.
                            </li>
                            <li>
                                To evaluate and improve our Services, products,
                                marketing, and your experience. We may process
                                your information when we believe it is necessary
                                to identify usage trends, determine the
                                effectiveness of our promotional campaigns, and
                                to evaluate and improve our Services, products,
                                marketing, and your experience..
                            </li>
                            <li>
                                To identify usage trends. We may process
                                information about how you use our Services to
                                better understand how they are being used so we
                                can improve them.
                            </li>
                            <li>
                                To determine the effectiveness of our marketing
                                and promotional campaigns. We may process your
                                information to better understand how to provide
                                marketing and promotional campaigns that are
                                most relevant to you.
                            </li>
                        </ul>
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="share" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        3. When and with whom do we share your personal
                        information?
                    </h2>
                    <p>
                        In Short: We may share information in specific
                        situations described in this section and/or with the
                        following third parties.
                    </p>
                    <p>
                        We may need to share your personal information in the
                        following situations:
                        <ul className="mx-10 list-disc">
                            <li>
                                <strong>Business Transfers.</strong> We may
                                share or transfer your information in connection
                                with, or during negotiations of, any merger,
                                sale of company assets, financing, or
                                acquisition of all or a portion of our business
                                to another company.
                            </li>
                            <li>
                                <strong>Offer Wall.</strong> Our application(s)
                                may display a third-party hosted "offer wall."
                                Such an offer wall allows third-party
                                advertisers to offer virtual currency, gifts, or
                                other items to users in return for the
                                acceptance and completion of an advertisement
                                offer. Such an offer wall may appear in our
                                application(s) and be displayed to you based on
                                certain data, such as your geographic area or
                                demographic information. When you click on an
                                offer wall, you will be brought to an external
                                website belonging to other persons and will
                                leave our application(s). A unique identifier,
                                such as your user ID, will be shared with the
                                offer wall provider in order to prevent fraud
                                and properly credit your account with the
                                relevant reward.
                            </li>
                        </ul>
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="thirdparty" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        4. What is our stance on third-party websites?
                    </h2>
                    <p>
                        In Short: We are not responsible for the safety of any
                        information that you share with third parties that we
                        may link to or who advertise on our Services, but are
                        not affiliated with, our Services.
                    </p>
                    <p>
                        The Services, including our offer wall, may link to
                        third-party websites, online services, or mobile
                        applications and/or contain advertisements from third
                        parties that are not affiliated with us and which may
                        link to other websites, services, or applications.
                        Accordingly, we do not make any guarantee regarding any
                        such third parties, and we will not be liable for any
                        loss or damage caused by the use of such third-party
                        websites, services, or applications. The inclusion of a
                        link towards a third-party website, service, or
                        application does not imply an endorsement by us. We
                        cannot guarantee the safety and privacy of data you
                        provide to any third parties. Any data collected by
                        third parties is not covered by this privacy notice. We
                        are not responsible for the content or privacy and
                        security practices and policies of any third parties,
                        including other websites, services, or applications that
                        may be linked to or from the Services. You should review
                        the policies of such third parties and contact them
                        directly to respond to your questions.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="cookies" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        5. Do we use cookies and other tracking technologies?
                    </h2>
                    <p>
                        In Short: We may use cookies and other tracking
                        technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to access or store
                        information, Specific information about how we use such
                        technologies and how you can refuse certain cookies is
                        set out in our Cookie Notice.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="social" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        6. How do we handle your social logins?
                    </h2>
                    <p>
                        In Short: If you choose to register or log in to our
                        Services using a social media account, we may have
                        access to certain information about you.
                    </p>
                    <p>
                        Our Services offer you the ability to register and log
                        in using your third-party social media account details
                        (like your Facebook or Twitter logins). Where you choose
                        to do this, we will receive certain profile information
                        about you from your social media provider. The profile
                        information we receive may vary depending on the social
                        media provider concerned, but will often include your
                        name, email address, friends list, and profile picture,
                        as well as other information you choose to make public
                        on such a social media platform.
                    </p>
                    <p>
                        We will use the information we receive only for the
                        purposes that are described in this privacy notice or
                        that are otherwise made clear to you on the relevant
                        Services. Please note that we do not control, and are
                        not responsible for, other uses of your personal
                        information by your third-party social media provider.
                        We recommend that you review their privacy notice to
                        understand how they collect, use, and share your
                        personal information, and how you can set your privacy
                        preferences on their sites and apps.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="retention" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        7. How long do we keep your information?
                    </h2>
                    <p>
                        In Short: We keep your information for as long as
                        necessary to fulfill the purposes outlined in this
                        privacy notice unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting,
                        or other legal requirements) No purpose in this notice
                        will require us keeping your personal information for
                        longer than four (4) months past the start of the idle
                        period of the user's account.
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="secure" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        8. How do we keep your information safe?
                    </h2>
                    <p>
                        In Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures.
                    </p>
                    <p>
                        We have implemented appropriate and reasonable technical
                        and organizational security measures designed to protect
                        the security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Services is at your
                        own risk. You should only access the Services within a
                        secure environment.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="minors" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        9. Do we collect information from minors?
                    </h2>
                    <p>
                        In Short: We do not knowingly collect data from or
                        market to children under 18 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are
                        the parent or guardian of such a minor and consent to
                        such minor dependent's use of the Services. If we learn
                        that personal information from users less than 18 years
                        of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete
                        such data from our records. If you become aware of any
                        data we may have collected from children under age 18,
                        please contact us at
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            {' '}
                            hello@air-rive.com
                        </a>
                        .
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="rights" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        10. What are your privacy rights?
                    </h2>
                    <p>
                        In Short: You may review, change, or terminate your
                        account at any time.
                    </p>
                    <p>
                        <strong>Withdrawing your consent</strong> If we are
                        relying on your consent to process your personal
                        information, which may be express and/or implied consent
                        depending on the applicable law, you have the right to
                        withdraw your consent at any time. You can withdraw your
                        consent at any time by contacting us by using the
                        contact details provided in the section{' '}
                        <a className="text-neon-blue hover:underline">
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                        </a>{' '}
                        below.
                    </p>
                    <p>
                        However, please note that this will not affect the
                        lawfulness of the processing before its withdrawal nor,
                        when applicable law allows, will it affect the
                        processing of your personal information conducted in
                        reliance on lawful processing grounds other than
                        consent.
                    </p>
                    <p>
                        Opting out of marketing and promotional communications:
                        You can unsubscribe from our marketing and promotional
                        communications at any time by clicking on the
                        unsubscribe link in the emails that we send, replying
                        "STOP" or "UNSUBSCRIBE" to the SMS messages that we
                        send, or by contacting us using the details provided in
                        the section{' '}
                        <a className="text-neon-blue hover:underline">
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                        </a>{' '}
                        below. You will then be removed from the marketing
                        lists. However, we may still communicate with you-for
                        example, to send you service-related messages that are
                        necessary for the administration and use of your
                        account, to respond to service requests, or for other
                        non-marketing purposes.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Account Information
                    </h3>
                    <p>
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can:
                        <ul className="mx-10 list-disc">
                            <li>
                                Upon your request to terminate your account, we
                                will deactivate or delete your account and
                                information from our active databases. However,
                                we may retain some information in our files to
                                prevent fraud, troubleshoot problems, assist
                                with any investigations, enforce our legal terms
                                and/or comply with applicable legal
                                requirements.
                            </li>
                            <li>
                                Cookies and similar technologies: Most Web
                                browsers are set to accept cookies by default.
                                If you prefer, you can usually choose to set
                                your browser to remove cookies and to reject
                                cookies. If you choose to remove cookies or
                                reject cookies, this could affect certain
                                features or services of our Services. You may
                                also opt out of interest-based advertising by
                                advertisers on our Services.
                            </li>
                        </ul>
                    </p>
                    <p>
                        If you have questions or comments about your privacy
                        nights, you may email us at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>
                        .
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="dnt" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        11. Controls for DO-NOT-TRACK features
                    </h2>
                    <p>
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track ("DNT")
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. At this
                        stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we
                        do not currently respond to DNT browser signals or any
                        other mechanism that automatically communicates your
                        choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="usrights" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        12. Do United States residents have specific privacy
                        rights?
                    </h2>
                    <p>
                        In Short: If you are a resident of Colorado, California,
                        Connecticut, Utah or Virginia, you are granted specific
                        rights regarding access to your personal information.
                    </p>
                    <strong>
                        What categories of personal information do we collect?
                    </strong>
                    <ul className="mx-10">
                        <li>
                            <strong>A. Identifiers</strong> Contact details,
                            such as real name, alias, postal address, telephone
                            or mobile contact number, unique personal
                            identifier, online identifier, Internet Protocol
                            address, email address, and account name
                        </li>
                        <li>
                            <strong>
                                B. Personal information as defined in the
                                California Customer Records statute
                            </strong>{' '}
                            Name, contact information, education, employment,
                            employment history, and financial information
                        </li>
                        <li>
                            <strong>
                                C. Protected classification characteristics
                                under state or federal law
                            </strong>{' '}
                            Gender and date of birth
                        </li>
                        <li>
                            <strong>D. Commercial Information</strong>{' '}
                            Transaction information, purchase history, financial
                            details, and payment information
                        </li>
                        <li>
                            <strong>E. Geolocation Data</strong> Device location
                        </li>
                        <li>
                            <strong>F. Sensitive Personal Information</strong>{' '}
                            Account login information, biometric data, debit or
                            credit card numbers, drivers' licenses and precise
                            geolocation
                        </li>
                    </ul>
                    <strong>
                        We will use and retain the collected personal
                        information as needed to provide the Services or for: as
                        long as the user has an active account.
                    </strong>
                    <p>
                        We may also collect other personal information outside
                        of these categories through instances where you interact
                        with us in person, online, or by phone or mail in the
                        context of:
                        <ul className="mx-10 list-disc">
                            <li>
                                Receiving help through our customer support
                                channels,
                            </li>
                            <li>
                                Participation in customer surveys or contests;
                                and
                            </li>
                            <li>
                                Facilitation in the delivery of our Services and
                                to respond to your inquiries.
                            </li>
                        </ul>
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Will your information be shared with anyone else?
                    </h3>
                    <p>
                        We may disclose your personal information with our
                        service providers pursuant to a written contract between
                        us and each service provider. Learn more about how we
                        disclose personal information to in the section,{' '}
                        <a className="text-neon-blue hover:underline">
                            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?"
                        </a>
                    </p>
                    <p>
                        We may use your personal information for our own
                        business purposes, such as for undertaking internal
                        research for technological development and
                        demonstration. This is not considered to be "selling" of
                        your personal information.
                    </p>
                    <p>
                        We have not disclosed, sold, or shared any personal
                        information to third parties for a business or
                        commercial purpose in the preceding twelve (12) months.
                        We will not sell or share personal information in the
                        future belonging to website visitors, users, and other
                        consumers.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        California Residents
                    </h3>
                    <p>
                        California Civil Code Section 1798.83, also known as the
                        "Shine The Light" law permits our users who are
                        California residents to request and obtain from us, once
                        a year and free of charge, information about categories
                        of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared
                        personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.
                    </p>
                    <p>
                        If you are under 18 years of age, reside in California,
                        and have a registered account with the Services, you
                        have the right to request removal of unwanted data that
                        you publicly post on the Services. To request removal of
                        such data, please contact us using the contact
                        information provided below and include the email address
                        associated with your account and a statement that you
                        reside in California. We will make sure the data is not
                        publicly displayed on the Services, but please be aware
                        that the data may not be completely or comprehensively
                        removed from all our systems (e.g., backups, etc.).
                    </p>
                    <strong>CCPA Privacy Notice</strong>
                    <p>
                        This section applies only to California residents. Under
                        the California Consumer Privacy Act (CCPA), you have the
                        rights listed below.
                    </p>
                    <p>
                        The California Code of Regulations defines a "residents"
                        as:
                    </p>
                    <ol className="mx-10 list-decimal">
                        <li>
                            every individual who is in the State of California
                            for other than a temporary or transitory purpose and
                        </li>
                        <li>
                            every individual who is domiciled in the State of
                            California who is outside the State of California
                            for a temporary or transitory purpose
                        </li>
                    </ol>
                    <p>All other individuals are defined as "non-residents."</p>
                    <p>
                        If this definition of "resident" applies to you, we must
                        adhere to certain rights and obligations regarding your
                        personal information.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Your rights with respect to your personal data
                    </h3>
                    <strong>
                        Right to request deletion of the data - Request to
                        delete
                    </strong>
                    <p>
                        You can ask for the deletion of your personal
                        information. If you ask us to delete your personal
                        information, we will respect your request and delete
                        your personal information, subject to certain exceptions
                        provided by law, such as (but not limited to) the
                        exercise by another consumer of his or her right to free
                        speech, our compliance requirements resulting from a
                        legal obligation, or any processing that may be required
                        to protect against illegal activities.
                    </p>
                    <strong>Right to be informed - Request to know</strong>
                    <p>
                        Depending on the circumstances, you have a right to
                        know:
                        <ul className="mx-10 list-disc">
                            <li>
                                whether we collect and use your personal
                                information;
                            </li>
                            <li>
                                the categories of personal information that we
                                collect
                            </li>
                            <li>
                                the purposes for which the collected personal
                                information is used:
                            </li>
                            <li>
                                whether we sell or share personal information to
                                third parties;
                            </li>
                            <li>
                                the categories of personal information that we
                                sold, shared, or disclosed for a business
                                purpose;
                            </li>
                            <li>
                                the categories of third parties to whom the
                                personal information was sold, shared, or
                                disclosed for a business purpose;
                            </li>
                            <li>
                                the business or commercial purpose for
                                collecting, selling, or sharing personal
                                information; and
                            </li>
                            <li>
                                the specific pieces of personal information we
                                collected about you.
                            </li>
                        </ul>
                    </p>
                    <strong>
                        In accordance with applicable law, we are not obligated
                        to provide or delete consumer information that is
                        de-identified in response to a consumer request or to
                        re-identify individual data to verify a consumer
                        request.
                    </strong>
                    <h3 className="font-heading text-xl font-bold">
                        Right to Non-Discrimination for the Exercise of a
                        Consumer's Privacy Rights
                    </h3>
                    <p>
                        We will not discriminate against you if you exercise
                        your privacy rights.
                    </p>
                    <strong>
                        Right to Limit Use and Disclosure of Sensitive Personal
                        Information
                    </strong>
                    <p>
                        If the business collects any of the following:
                        <ul className="mx-10 list-disc">
                            <li>
                                social security information, drivers' licenses,
                                state ID cards, passport numbers
                            </li>
                            <li>account login information</li>
                            <li>
                                credit card numbers, financial account
                                information, or credentials allowing access to
                                such accounts
                            </li>
                            <li>precise geolocation</li>
                            <li>
                                racial or ethnic origin, religious or
                                philosophical beliefs, union membership
                            </li>
                            <li>
                                the contents of email and text, unless the
                                business is the intended recipient of the
                                communication
                            </li>
                            <li>
                                genetic data, biometric data, and health data
                            </li>
                            <li>
                                data concerning sexual orientation and sex life
                            </li>
                        </ul>
                    </p>
                    <strong>
                        you have the right to direct that business to limit its
                        use of your sensitive personal information to that use
                        which is necessary to perform the Services.
                    </strong>
                    <p>
                        Once a business receives your request, they are no
                        longer allowed to use or disclose your sensitive
                        personal information for any other purpose unless you
                        provide consent for the use or disclosure of sensitive
                        personal information for additional purposes.
                    </p>
                    <p>
                        Please note that sensitive personal information that is
                        collected or processed without the purpose of inferring
                        characteristics about a consumer is not covered by this
                        right, as well as the publicly available information.
                    </p>
                    <p>
                        To exercise your right to limit use and disclosure of
                        sensitive personal information, please email{' '}
                        <a
                            className="text-neon-blue hover:underline"
                            href="mailto:hello@air-rive.com"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or submit a data subject access request.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Verification process
                    </h3>
                    <p>
                        Upon receiving your request, we will need to verify your
                        identity to determine you are the same person about whom
                        we have the information in our system. These
                        verification efforts require us to ask you to provide
                        information so that we can match it with information you
                        have previously provided us. For instance, depending on
                        the type of request you submit, we may ask you to
                        provide certain information so that we can match the
                        information you provide with the information we already
                        have on file, or we may contact you through a
                        communication method (e.g.. phone or email) that you
                        have previously provided to us. We may also use other
                        verification methods as the circumstances dictate.
                    </p>
                    <p>
                        We will only use personal information provided in your
                        request to verify your identity or authority to make the
                        request. To the extent possible. we will avoid
                        requesting additional information from you for the
                        purposes of verification. However, if we cannot verify
                        your identity from the information already maintained by
                        us, we may request that you provide additional
                        information for the purposes of verifying your identity
                        and for security or fraud-prevention purposes. We will
                        delete such additionally provided information as soon as
                        we finish verifying you.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Other privacy rights
                    </h3>
                    <p>
                        You may object to the processing of your personal
                        information.
                    </p>
                    <p>
                        You may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the information.
                    </p>
                    <p>
                        You can designate an authorized agent to make a request
                        under the CCPA on your behalf. We may deny a request
                        from an authorized agent that does not submit proof that
                        they have been validly authorized to act on your behalf
                        in accordance with the CCPA.
                    </p>
                    <p>
                        You may request to opt out from future selling or
                        sharing of your personal information to third parties.
                        Upon receiving an opt-out request, we will act upon the
                        request as soon as feasibly possible, but no later than
                        fifteen (15) days from the date of the request
                        submission.
                    </p>
                    <p>
                        To exercise these rights, you can contact us by
                        submitting a data subject access request, by email at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>
                        , or by referring to the contact details at the bottom
                        of this document. If you have a complaint about how we
                        handle your data, we would like to hear from you.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Colorado Residents
                    </h3>
                    <p>
                        This section applies only to Colorado residents. Under
                        the Colorado Privacy Act (CPA), you have the rights
                        listed below. However, these rights are not absolute,
                        and in certain cases, we may decline your request as
                        permitted by law.
                    </p>
                    <ul className="mx-10 list-disc">
                        <li>
                            Right to be informed whether or not we are
                            processing your personal data
                        </li>
                        <li>Right to access your personal data</li>
                        <li>
                            Right to correct inaccuracies in your personal data
                        </li>
                        <li>Right to request deletion of your personal data</li>
                        <li>
                            Right to obtain a copy of the personal data you
                            previously shared with us
                        </li>
                        <li>
                            Right to opt out of the processing of your personal
                            data if it is used for targeted advertising, the
                            sale of personal data, or profiling in furtherance
                            of decisions that produce legal or similarly
                            significant effects ("profiling")
                        </li>
                    </ul>
                    <p>
                        To submit a request to exercise these rights described
                        above, please email{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or submit a data subject access request.
                    </p>
                    <p>
                        If we decline to take action regarding your request and
                        you wish to appeal our decision, please email us at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>
                        . Within forty- five (45) days of receipt of an appeal,
                        we will inform you in writing of any action taken or not
                        taken in response to the appeal, including a written
                        explanation of the reasons for the decisions.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Connecticut Residents
                    </h3>
                    <p>
                        This section applies only to Connecticut residents.
                        Under the Connecticut Data Privacy Act (CTDPA), you have
                        the rights listed below. However, these rights are not
                        absolute, and in certain cases, we may decline your
                        request as permitted by law.
                    </p>
                    <ul className="mx-10 list-disc">
                        <li>
                            Right to be informed whether or not we are
                            processing your personal data
                        </li>
                        <li>Right to access your personal data</li>
                        <li>
                            Right to correct inaccuracies in your personal data
                        </li>
                        <li>Right to request deletion of your personal data</li>
                        <li>
                            Right to obtain a copy of the personal data you
                            previously shared with us
                        </li>
                        <li>
                            Right to opt out of the processing of your personal
                            data if it is used for targeted advertising, the
                            sale of personal data, or profiling in furtherance
                            of decisions that produce legal or similarly
                            significant effects ("profiling")
                        </li>
                    </ul>
                    <p>
                        To submit a request to exercise these rights described
                        above, please email{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or submit a data subject access request.
                    </p>
                    <p>
                        If we decline to take action regarding your request and
                        you wish to appeal our decision, please email us at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>
                        . Within sixty (60) days of receipt of an appeal, we
                        will inform you in writing of any action taken or not
                        taken in response to the appeal, including a written
                        explanation of the reasons for the decisions.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Utah Residents
                    </h3>
                    <p>
                        This section applies only to Utah residents. Under the
                        Utah Consumer Privacy Act (UCPA), you have the rights
                        listed below. However, these rights are not absolute,
                        and in certain cases, we may decline your request as
                        permitted by law.
                    </p>
                    <ul className="mx-10 list-disc">
                        <li>
                            Right to be informed whether or not we are
                            processing your personal data
                        </li>
                        <li>Right to access your personal data</li>
                        <li>Right to request deletion of your personal data</li>
                        <li>
                            Right to obtain a copy of the personal data you
                            previously shared with us
                        </li>
                        <li>
                            Right to opt out of the processing of your personal
                            data if it is used for targeted advertising or the
                            sale of personal data
                        </li>
                    </ul>
                    <p>
                        To submit a request to exercise these rights described
                        above, please email{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or submit a data subject access request.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Virginia Residents
                    </h3>
                    <p>
                        Under the Virginia Consumer Data Protection Act (VCDPA):
                        <ul className="mx-10 list-disc">
                            <li>
                                "Consumer" means a natural person who is a
                                resident of the Commonwealth acting only in an
                                individual or household context. It does not
                                include a natural person acting in a commercial
                                or employment context.
                            </li>
                            <li>
                                "Personal data" means any information that is
                                linked or reasonably linkable to an identified
                                or identifiable natural person. "Personal data"
                                does not include de-identified data or publicly
                                available information
                            </li>
                            <li>
                                "Sale of personal data" means the exchange of
                                personal data for monetary consideration.
                            </li>
                        </ul>
                    </p>
                    <p>
                        If this definition of "consumer" applies to you, we must
                        adhere to certain rights and obligations regarding your
                        personal data.
                    </p>
                    <p>
                        Your rights with respect to your personal data
                        <ul className="mx-10 list-disc">
                            <li>
                                Right to be informed whether or not we are
                                processing your personal data
                            </li>
                            <li>Right to access your personal data</li>
                            <li>
                                Right to correct inaccuracies in your personal
                                data
                            </li>
                            <li>
                                Right to request deletion of your personal data
                            </li>
                            <li>
                                Right to obtain a copy of the personal data you
                                previously shared with us
                            </li>
                            <li>
                                Right to opt out of the processing of your
                                personal data if it is used for targeted
                                advertising, the sale of personal data, or
                                profiling in furtherance of decisions that
                                produce legal or similarly significant effects
                                ("profiling")
                            </li>
                        </ul>
                    </p>
                    <strong>
                        Exercise your rights provided under the Virginia VCDPA
                    </strong>
                    <p>
                        You may contact us by email at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or submit a data subject access request.
                    </p>
                    <p>
                        If you are using an authorized agent to exercise your
                        rights, we may deny a request if the authorized agent
                        does not submit proof that they have been validly
                        authorized to act on your behalf.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Verification process
                    </h3>
                    <p>
                        We may request that you provide additional information
                        reasonably necessary to verify you and your consumer's
                        request. If you submit the request through an authorized
                        agent, we may need to collect additional information to
                        verify your identity before processing your request.
                    </p>
                    <p>
                        Upon receiving your request, we will respond without
                        undue delay, but in all cases, within forty-five (45)
                        days of receipt. The response period may be extended
                        once by forty-five (45) additional days when reasonably
                        necessary. We will inform you of any such extension
                        within the initial 45-day response period, together with
                        the reason for the extension.
                    </p>
                    <h3 className="font-heading text-xl font-bold">
                        Right to appeal
                    </h3>
                    <p>
                        If we decline to take action regarding your request, we
                        will inform you of our decision and reasoning behind it.
                        If you wish to appeal our decision, please email us at
                        hello@air-rive.com. Within sixty (60) days of receipt of
                        an appeal, we will inform you in writing of any action
                        taken or not taken in response to the appeal, including
                        a written explanation of the reasons for the decisions.
                        If your appeal is denied, you may contact the Attorney
                        General to submit a complaint.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="updates" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        13. Do we make updates to this notice?
                    </h2>
                    <p>
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        "Revised" date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy notice frequently to be informed of
                        how we are protecting your information.
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="contact" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        14. How can you contact us about this notice?
                    </h2>
                    <p>
                        If you have questions or comments about this notice, you
                        may email us at{' '}
                        <a
                            href="mailto:hello@air-rive.com"
                            className="text-neon-blue hover:underline"
                        >
                            hello@air-rive.com
                        </a>{' '}
                        or contact us by post at AIR-RIVE Corporate. 3447 East
                        4th Street Tucson, AZ 85716 United States
                    </p>
                </div>
                <hr className="h-1 rounded-2xl bg-gray-200" />
                <div id="review" className="flex flex-col gap-3">
                    <h2 className="font-heading text-2xl font-bold">
                        15. How can you review, update, or delete the data we
                        collect from you?
                    </h2>
                    <p>
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, change that
                        information, or delete it. To request to review, update,
                        or delete your personal information, please fill out and
                        submit a data subject access request.
                    </p>
                </div>
            </div>
        </section>
    )
}
