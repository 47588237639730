import CareerCard from '../../components/CareerCard.tsx'

function Careers() {
    return (
        <section className="flex flex-col gap-10 scroll-smooth">
            <div className="flex h-80 flex-col items-center justify-center gap-3 rounded-xl bg-neon-blue p-5 text-white">
                <h1 className="text-center font-heading text-3xl font-bold">
                    AIR-RIVE Careers.
                </h1>
                <p className="text-2xl">Hungry for a new career?</p>
            </div>
            <div className="flex flex-col gap-5 md:flex-row">
                <CareerCard />
                <CareerCard />
                <CareerCard />
            </div>
        </section>
    )
}

export default Careers
