// @ts-ignore
export default function TeamCard({ name, role, bio }) {
    return (
        <div className="flex h-fit w-full flex-col rounded-xl p-3 outline outline-2 outline-gray-400 md:w-1/4">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/air-rive-landing.appspot.com/o/corporate%2FLayer%201.svg?alt=media&token=d0e58ef1-4f7d-4f9b-a475-27c197158e3e"
                alt="Team member photo"
                className="self-center"
            />
            <h2 className="mt-3 font-heading text-2xl font-bold text-neon-blue">
                {name}
            </h2>
            <em>{role}</em>
            <hr className="m-3 h-1 rounded-2xl bg-gray-400" />
            <p>{bio}</p>
        </div>
    )
}
