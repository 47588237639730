import TeamCard from '../../components/TeamCard.tsx'

function About() {
    return (
        <section className="flex flex-col gap-10 scroll-smooth">
            <div className="flex h-96 flex-row justify-center gap-20 rounded-xl bg-neon-blue p-5 text-center text-white lg:p-0">
                <div className="mr-10 hidden h-full w-1 rotate-12 rounded-xl bg-white py-20 lg:block" />
                <div className="flex w-fit flex-col items-center justify-center gap-3">
                    <h2 className="font-heading text-5xl font-bold">
                        AIR-RIVE Corporate
                    </h2>
                    <p className="text-2xl">
                        We go <span className="font-bold italic">high</span>,
                        because they went{' '}
                        <span className="font-bold italic">low</span>.
                    </p>
                </div>
                <div className="ml-10 hidden h-full w-1 rotate-12 rounded-xl bg-white lg:block" />
            </div>
            <div className="flex w-full flex-col gap-3">
                <h1 className="font-heading text-3xl font-bold">
                    Our <span className="text-neon-blue">vision</span>
                </h1>
                <hr className="h-1 w-1/3 rounded-xl bg-gray-300" />
                <p>
                    Let's be honest, food delivery has gotten stale, from long
                    delivery times, expensive delivery fees, unsafe food
                    handling/tampering and human error.
                </p>

                <h4 className="font-heading text-xl font-bold">
                    You deserve <span className="text-neon-blue">more</span>,
                    and we’re here to{' '}
                    <span className="text-neon-blue">help</span>.{' '}
                </h4>

                <p>
                    <span className="font-heading font-bold">
                        You deserve{' '}
                        <span className="text-neon-blue">sustainability</span>,
                    </span>{' '}
                    our drones operations are entirely electric, cutting down on
                    waste and excess emissions from food delivery vehicles.
                </p>

                <p>
                    <span className="font-heading font-bold">
                        You deserve{' '}
                        <span className="text-neon-blue">efficiency</span>,
                    </span>{' '}
                    no more long wait times or meals getting held up from
                    foot/vehicle traffic, AIR-RIVE moves from our point A to
                    your Point B.{' '}
                </p>

                <p>
                    <span className="font-heading font-bold">
                        You deserve{' '}
                        <span className="text-neon-blue">safety</span>,
                    </span>{' '}
                    currently 30% of all delivery personnel admit to food
                    tampering (and those are just the people who admit to it).
                    Don’t worry though, our drones won’t take a bite out of your
                    meal.{' '}
                </p>

                <p>
                    <span className="font-heading font-bold">
                        You deserve to{' '}
                        <span className="text-neon-blue">save</span>,
                    </span>{' '}
                    we won’t ask you for unnecessary tips or weird extra service
                    charges.{' '}
                </p>

                <p>
                    <span className="font-heading font-bold">
                        You deserve the{' '}
                        <span className="text-neon-blue">future</span>
                    </span>{' '}
                    and the future we see is people just like you looking sky
                    high and only settling for what{' '}
                    <span className="font-heading font-bold">
                        you deserve.{' '}
                        <span className="text-neon-blue">The best</span>.
                    </span>
                </p>
            </div>

            <div className="flex flex-col gap-3">
                <div>
                    <h1 className="font-heading text-3xl font-bold">
                        Our <span className="text-neon-blue">team</span>
                    </h1>
                    <p>The amazing team powering our dreams.</p>
                </div>
                <hr className="h-1 w-1/3 rounded-xl bg-gray-300" />
                <div className="flex flex-col gap-5 md:flex-row md:justify-center">
                    <TeamCard
                        name="Charles Becker"
                        role="Chief Executive Officer"
                        bio={
                            'Charles is responsible for managing daily operations, employment, capital allocation ' +
                            'and organizational aspects of AIR-RIVE. He is currently studying business and engineering ' +
                            'at the University of Arizona. He has led several successful business creations in Bauchi, ' +
                            'Nigeria for community development.'
                        }
                    />
                    <TeamCard
                        name="Max Blowers"
                        role="Chief Technology Officer"
                        bio={
                            'Max is responsible for designing and building the apps, websites, and API’s. He is ' +
                            'currently studying computer science at the University of Arizona. He has spent several ' +
                            'years honing his skills as a designer and developer. He has thoughtfully designed the ' +
                            'entire user experience, creating a pleasing experience.'
                        }
                    />
                </div>
            </div>
        </section>
    )
}

export default About
