import { useState } from 'react'
import ResText from '../components/ResText.tsx'
import { arAPIURL } from '../utils/envLoad.ts'

export default function Unsub() {
    const [email, setEmail] = useState('')
    const [res, setRes] = useState({
        success: false,
        message: '',
    })

    const mailUnsubscribe = async (email: string) => {
        const response = await fetch(arAPIURL + '/v1/mailr/remove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
            }),
        })

        const result = await response.json()
        setRes(result)
        console.log(result)
    }

    return (
        <section className="my-3 flex flex-col items-center gap-3">
            <div className="flex w-full flex-col items-center gap-3 md:w-1/2">
                <h1 className="font-heading text-3xl font-bold">
                    We're sad to{' '}
                    <span className="text-neon-blue">see you go</span>.
                </h1>
                <ResText success={res.success} msg={res.message} />
                <input
                    placeholder="Your email address"
                    className="w-full rounded-2xl px-3 py-2 outline outline-2 outline-neon-blue"
                    type="email"
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                    value={email}
                />
                <button
                    onClick={() => mailUnsubscribe(email)}
                    className="w-full rounded-2xl bg-neon-blue px-3 py-2 font-heading font-bold text-white outline-none outline outline-2 outline-offset-0 outline-neon-blue transition duration-300 hover:bg-white hover:text-neon-blue"
                >
                    Unsubscribe
                </button>
            </div>
        </section>
    )
}
