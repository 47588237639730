import { useState } from 'react'
import ResText from '../components/ResText.tsx'
import { httpsCallable } from 'firebase/functions'

import '../index.css'
import { functions } from '../utils/firebase.ts'

function Home() {
    const [email, setEmail] = useState('')
    const [res, setRes] = useState({
        success: false,
        message: '',
    })

    const mailSubscribe = async (email: string) => {
        // mailer-newUpdatesContact
        const subscribeFunction = httpsCallable(
            functions,
            'mailer-newUpdatesContact'
        )
        subscribeFunction({ email: email })
            .then((res) => {
                console.log(res)
                setRes({
                    success: true,
                    message: "You've subscribed to our mailing list.",
                })
            })
            .catch((err) => {
                console.error('Oh No!', err)
                setRes({
                    success: false,
                    message:
                        'Oh no! We encountered an error. Please try again later.',
                })
            })
        setEmail('')
    }

    return (
        <section className="flex flex-col gap-10 scroll-smooth">
            <div className="flex h-96 flex-row justify-center gap-20 rounded-xl bg-neon-blue p-5 text-center text-white lg:p-0">
                <div className="mr-10 hidden h-full w-1 rotate-12 rounded-xl bg-white py-20 lg:block" />
                <div className="flex w-fit flex-col items-center justify-center gap-3">
                    <h2 className="font-heading text-5xl font-bold">
                        Join us in the sky.
                    </h2>
                    <a
                        href="/corp/"
                        className="rounded-xl bg-white px-5 py-2 font-bold text-neon-blue outline outline-2 outline-white transition duration-300 hover:bg-neon-blue hover:text-white"
                    >
                        Learn More{' '}
                        <i className="fa-regular fa-computer-mouse fa-bounce ml-2"></i>
                    </a>
                </div>
                <div className="ml-10 hidden h-full w-1 rotate-12 rounded-xl bg-white lg:block" />
            </div>

            <div className="flex h-fit flex-col gap-3 rounded-xl py-2 shadow-2xl outline outline-2 outline-gray-200 lg:h-36 lg:flex-row">
                <div className="flex flex-col items-center lg:w-1/4 lg:justify-center">
                    <h3 className="py-3 font-heading text-3xl font-bold lg:py-10">
                        Here for{' '}
                        <span className="text-neon-blue">
                            you{' '}
                            <i className="fa-solid fa-drone-front fa-bounce"></i>
                        </span>
                    </h3>
                </div>
                <div className="hidden h-full w-1 -rotate-12 rounded-xl bg-neon-blue lg:block"></div>
                <div className="mx-5 flex flex-col items-center justify-center text-xl lg:m-5 lg:w-1/2">
                    AIR-RIVE’s mission is to provide food & more delivered to
                    your door with drones. Fast delivery. No tip. No hassle.
                </div>
                <div className="h-full w-1 -rotate-12 rounded-xl bg-neon-blue"></div>
            </div>

            <div className="flex h-fit flex-col gap-3 rounded-xl bg-neon-blue p-5 py-2 text-white shadow-2xl shadow-gray-500 outline-gray-200 lg:h-36 lg:flex-row">
                <div className="flex flex-col items-center justify-center lg:w-1/4">
                    <h3 className="py-3 text-center font-heading text-3xl font-bold lg:py-10 lg:text-left">
                        Your favorites <br />&{' '}
                        <span className="">
                            more{' '}
                            <i className="fa-solid fa-fork-knife fa-bounce"></i>
                        </span>
                    </h3>
                </div>
                <div className="h-full w-1 rotate-12 rounded-xl bg-white"></div>
                <div className="mx-5 flex flex-col items-center justify-center text-xl text-white lg:m-5 lg:w-1/2">
                    From fast food staples to essentials we want you to have it
                    all.
                </div>
                <div className="h-full w-1 rotate-12 rounded-xl bg-white"></div>
            </div>

            <div className="flex h-fit flex-col gap-3 rounded-xl bg-mhc-black py-2 text-mhc-white opacity-80 shadow-2xl outline outline-2 outline-gray-200 lg:h-36 lg:flex-row">
                <div className="flex flex-col items-center justify-center lg:w-1/4">
                    <h3 className="p-3 text-center font-heading text-3xl font-bold lg:py-10 lg:text-left">
                        More of what <br /> you{' '}
                        <span className="text-test">
                            love <i className="fa-regular fa-heart fa-beat"></i>
                        </span>
                    </h3>
                </div>
                <div className="h-full w-1 -rotate-12 rounded-xl bg-mhc-white"></div>
                <div className="mx-5 flex flex-col items-center justify-center text-xl lg:m-5 lg:w-1/2">
                    $0 Delivery Fees, Exclusive deals/sales and Air Packages…
                    all for $9.69 a month
                </div>{' '}
                <div className="h-full w-1 -rotate-12 rounded-xl bg-mhc-white"></div>
            </div>

            <div className="flex h-fit flex-col gap-3 rounded-xl py-2 shadow-2xl outline outline-2 outline-gray-200 lg:h-36 lg:flex-row">
                <div className="flex flex-col items-center justify-center text-center lg:w-1/2 lg:text-left">
                    <h3 className="p-3 font-heading text-3xl font-bold lg:py-10">
                        Use the future <br />
                        to your{' '}
                        <span className="text-neon-blue">advantage</span>
                    </h3>
                </div>
                <div className="h-full w-1 rotate-12 rounded-xl bg-neon-blue"></div>
                <div className="flex flex-col items-center justify-center gap-2 lg:w-1/2">
                    <h5 className="font-heading font-bold">
                        Become a partner today
                    </h5>
                    <a
                        href="https://partners.air-rive.com"
                        className="rounded-xl bg-neon-blue px-5 py-2 font-bold text-white outline outline-2 outline-neon-blue transition duration-300 hover:bg-white hover:text-neon-blue"
                    >
                        Get Started
                    </a>
                </div>
            </div>

            <div className="my-10 flex flex-col items-center rounded-2xl p-5 shadow-2xl outline outline-2 outline-gray-200">
                <div className="flex flex-col items-center gap-3 md:w-1/2">
                    <h2 className="font-heading text-3xl font-bold">
                        Let's stay{' '}
                        <span className="text-neon-blue">in touch</span>
                    </h2>
                    <p>
                        Receive Exclusive deals, promotional material and
                        AIR-RIVE updates. You can{' '}
                        <a
                            href="/unsubscribe"
                            className="text-neon-blue hover:underline"
                        >
                            unsubscribe
                        </a>{' '}
                        at any time.
                    </p>
                    <ResText success={res.success} msg={res.message} />
                    <input
                        placeholder="Your email address"
                        className="w-full rounded-2xl px-3 py-2 outline outline-2 outline-neon-blue"
                        type="email"
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        value={email}
                    />
                    <button
                        onClick={() => mailSubscribe(email)}
                        className="w-full rounded-2xl bg-neon-blue px-3 py-2 font-bold text-white outline-none outline outline-2 outline-offset-0 outline-neon-blue transition duration-300 hover:bg-white hover:text-neon-blue"
                    >
                        Subscribe
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Home
