import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './pages/Home.tsx'
import ErrorPage from './pages/ErrorPage.tsx'
import About from './pages/corp/About.tsx'
import Careers from './pages/corp/Careers.tsx'
import { TOS } from './pages/legal/TOS.tsx'
import { Privacy } from './pages/legal/Privacy.tsx'
import Unsub from './pages/Unsub.tsx'

import * as Sentry from '@sentry/react'

Sentry.init({
    dsn: 'https://13299e72fce2cdeae1038b62ace06599@o4506681127862272.ingest.sentry.io/4506687364792320',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/yourserver\.io\/api/,
            ],
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const router = createBrowserRouter([
    {
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/corp',
                children: [
                    {
                        path: '/corp/',
                        element: <About />,
                    },
                    {
                        path: '/corp/careers',
                        element: <Careers />,
                    },
                ],
            },
            {
                path: '/legal',
                children: [
                    {
                        path: '/legal/tos',
                        element: <TOS />,
                    },
                    {
                        path: '/legal/privacy',
                        element: <Privacy />,
                    },
                ],
            },
            {
                path: 'unsubscribe',
                element: <Unsub />,
            },
        ],
    },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
    <RouterProvider router={router} />
)
