import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
    const error: any = useRouteError()
    console.error(error)

    return (
        <div className="m-5 flex min-h-screen flex-col gap-5 font-body text-lg">
            <nav className="flex flex-row items-center justify-center">
                <a
                    className="flex w-fit flex-row items-center justify-center gap-3 rounded-2xl px-3 py-2 font-heading text-4xl font-extrabold text-neon-blue outline-2 outline-neon-blue hover:outline"
                    href={'/'}
                >
                    <img
                        className="h-12"
                        src="https://air-rive..net/corporate/AR-Filled.svg"
                        alt="Bird carrying a bag, on a blue background."
                    />
                    <h1>AIR-RIVE</h1>
                </a>
            </nav>
            <div className="flex h-full flex-col items-center justify-center gap-3">
                <h1 className="font-heading text-3xl font-bold">Oops! :(</h1>
                <p>It looks like something went wrong.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
                <a
                    href="/"
                    className="text-xl font-bold text-neon-blue underline-offset-2 hover:underline"
                >
                    Take me to safety!
                </a>
            </div>
        </div>
    )
}
