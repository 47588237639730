// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
function Alert({ type, url, msg, location }) {
    if (type == 'info') {
        return (
            <div className="flex flex-row items-center gap-2 rounded-2xl px-3 py-2 font-bold text-neon-blue outline outline-2 outline-neon-blue">
                <i className="fa-solid fa-circle-info rounded-3xl text-2xl"></i>
                <p>{msg}</p>
                <p>Affected Location(s): {location}</p>
                <a
                    className="underline hover:text-light-coral"
                    href={`https://${url}/`}
                    target="_blank"
                >
                    {url}
                </a>
            </div>
        )
    } else if (type == 'warn') {
        return (
            <div className="flex flex-row items-center gap-2 rounded-2xl px-3 py-2 font-bold text-gold outline outline-2 outline-gold">
                <i className="fa-solid fa-triangle-exclamation rounded-3xl text-2xl"></i>
                <p>{msg}</p>
                <p>Affected Location(s): {location}</p>
                <a
                    className="text-neon-blue underline hover:text-light-coral"
                    href={`https://${url}/`}
                    target="_blank"
                >
                    {url}
                </a>
            </div>
        )
    } else if (type == 'error') {
        return (
            <div className="flex flex-row items-center gap-2 rounded-2xl px-3 py-2 font-bold text-light-coral outline outline-2 outline-light-coral">
                <i className="fa-solid fa-circle-exclamation rounded-3xl text-2xl"></i>
                <p>{msg}</p>
                <p>Affected Location(s): {location}</p>
                <a
                    className="text-neon-blue underline hover:text-light-coral"
                    href={`https://${url}/`}
                    target="_blank"
                >
                    {url}
                </a>
            </div>
        )
    } else {
        return
    }
}

export default Alert
