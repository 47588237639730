function CareerCard() {
    return (
        <div className="w-full rounded-xl p-3 text-neon-blue outline outline-2 outline-neon-blue md:w-1/3">
            <div className="flex flex-row items-center justify-between">
                <h2 className="font-heading text-2xl font-bold">Job Title</h2>
                <em className="text-black">$130k</em>
            </div>
            <p>
                <i className="fa-solid fa-location-dot"></i>{' '}
                <span className="text-black">Tucson, AZ</span>
            </p>
        </div>
    )
}

export default CareerCard
